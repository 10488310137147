<div class="form-group tags-container">
    <div *ngIf="isPopupVisible" id="popup">
        {{popupMessage}}
    </div>
    <label for="tag-input">{{ label }}: <small class="label-description">{{ description }}</small> </label>
    <div class="tags-input-wrapper">
        <span class="tag" *ngFor="let tag of tags; let i = index">
            {{ tag }}
            <a (click)="removeTag(i)">&times;</a>
        </span>

        <input
            id="tag-input"
            type="text"
            [placeholder]="placeholder"
            [(ngModel)]="tagInput"
            (focusout)="onBlur()"
            (keydown)="onKeyDown($event)"/>
    </div>
</div>
