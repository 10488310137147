import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-tags-input',
  templateUrl: './tags-input.component.html',
  styleUrls: ['./tags-input.component.css'],
})

export class TagsInputComponent implements OnChanges {
  tagInput = '';
  @Input() duplicateMessage = 'This tag already exists!';
  @Input() validationMessage = '';
  @Input() label = 'Tags input';
  @Input() description = ': (Type , for save current tag)';
  @Input() placeholder = 'Enter a tag';
  @Input() set tags(tags: any) {
    if (Array.isArray(tags)) {
      this._tags = tags;
    } else if (tags !== undefined && tags !== null) {
      this._tags = tags.replace(' ', '').split(',');
    } else {
      this._tags = [];
    }
    this._tags = this._tags.filter((tag: any) => tag?.length > 1);
  }
  get tags() {
    return this._tags;
  }
  _tags: any[] = [];
  popupMessage = '';
  isPopupVisible = false;
  @Output() change = new EventEmitter<string[]>();
  @Input() validateTag: (tag: string) => boolean = () => true;

  ngOnChanges(changes: SimpleChanges): void {
  }

  onKeyDown(event: KeyboardEvent): void {
    const inputValue = this.tagInput.trim();

    if ((event.key === 'Enter' || event.key === ',' || event.key === ' ') && inputValue) {
      event.preventDefault();
      this.addTag(inputValue);
      this.tagInput = '';
    }
  }

  addTag(tag: string): void {
    const isValid = this.validateTag(tag);
    const isDuplicate = this.tags.includes(tag);

    if (tag && isValid && !isDuplicate) {
      this.tags.push(tag);
      this.change.emit(this.tags);
    } else {
      if (!isValid) { this.showPopup(this.validationMessage); }
      if (isDuplicate) { this.showPopup(this.duplicateMessage); }
      console.error('Invalid or duplicate tag:', tag);
    }
  }

  removeTag(index: number): void {
    this.tags.splice(index, 1);
    this.change.emit(this.tags);
  }
  showPopup(message: string) {
    this.popupMessage = message;
    this.isPopupVisible = true;

    setTimeout(() => {
      this.isPopupVisible = false;
    }, 3000);
  }

  onBlur(): void {
    const inputValue = this.tagInput.trim();
    if (inputValue) {
      this.addTag(inputValue);
      this.tagInput = '';
    }
  }
}
