<ng-http-loader
  [backgroundColor]="'#047bf8'"
  [spinner]="spinkit.skWave"
  [debounceDelay]="100"
  [minDuration]="300"
  [filteredUrlPatterns]="utility.getExludedLoadingUrls()">
</ng-http-loader>
<div class="all-wrapper menu-side with-pattern" [hidden]="!isSecretTrue">
  <div class="auth-box-w ng-star-inserted">
    <div class="logo-w">
      <a href="/"><img alt="" src="assets/img/Paynote_-_New_Blue.svg"></a>
    </div>
    <div>
      <h4 class="auth-header">Secret Key</h4>
      <form name="form" autocomplete="nope" (ngSubmit)="f.form.valid && getListStatusApp()" #f="ngForm" class="form-validation">
        <div class="form-group" [ngClass]="{ 'has-error has-danger': !key.valid && (key.dirty || key.touched) }">
          <!--<label for="">Secret Key</label>-->
          <input name="amount" class="form-control" placeholder="Secret Key" type="text" required
                 [(ngModel)]="secret.key" #key="ngModel">
          <div *ngIf="!key.valid && (key.dirty || key.touched)"
               class="help-block form-text with-errors form-control-feedback">
                <span *ngIf="key.errors.required">
                      Secret Key is required
               </span>
          </div>
        </div>
        <div class="buttons-w">
          <button [disabled]="loading || !f.form.valid" class="btn btn-primary btn-rounded btn-rounded-margin">Login
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="content-box" [hidden]="isSecretTrue">
  <div class="element-wrapper">
    <div class="element-box col-9" style="margin: auto;">
      <div class="thank-you-page">
        <div class="text-center">
          <a href="/login">
            <img src="assets/img/Paynote_-_New_Blue.svg" style="max-height: 70px;">
          </a>
        </div>
        <hr>
        <br><br>
        <div class="col-md-9 row" style="margin: auto;float: none; max-width: 1000px; width: 100%;">
          <!--loop1-->
          <div class="col-sm-12 col-md-6 col-xxxl-4 block-status-page" *ngFor="let map of statusResponse; let i = index;">
            <span class="element-box el-tablo" style="position: relative;">
            <div class="value"><img height="50px" alt="" src="{{map.src}}"></div>
            <div class="trending trending-up-basic" style="position: absolute; top: 0; right: 0; margin-right: 10px;">
              <i class="fa fa-times" style="font-size: 3em; color: red;" *ngIf="!map.response.success"></i>
              <i class="fa fa-check" style="font-size: 3em;" *ngIf="map.response.success"></i>
             </div>
            </span>
          <!--loop1-->
          </div>
          <div class="col-sm-12 col-xxxl-12">
            <div class="element-wrapper">
              <div class="element-box">
                <div class="table-responsive">
                  <table class="table table-lightborder">
                    <thead>
                    <tr>
                      <th>Title</th>
                      <th class="text-center">1 hour</th>
                      <th class="text-center">3 hour</th>
                      <th class="text-center">24 hour</th>
                    </tr>
                    </thead>
                    <tbody>
                    <!--loop1-->
                    <tr *ngFor="let map2 of statusResponse2; let i = index;">
                      <td class="nowrap">{{map2.value}}</td>
                      <td class="text-center">{{map2.response.list.hour_1}}</td>
                      <td class="text-center">{{map2.response.list.hour_3}}</td>
                      <td class="text-center">{{map2.response.list.hour_24}}</td>
                    </tr>
                    <!--loop1-->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
            <div class="col-sm-12 col-xxxl-12">
                <div class="element-wrapper">
                    <div class="element-box">
                        <app-tags-input
                            [label]="'Report Emails'"
                            [validateTag]="validateEmail"
                            [placeholder]="'Enter Email'"
                            [validationMessage]="'Please enter valid email.'"
                            [duplicateMessage]="'This email already exists!'"
                            [description]="'(Enter the email addresses and separate them with a comma.)'"
                            [tags]="toEmails.to_emails?.split(',') ?? []"
                            (change)="setToEmails($event)"
                        >

                        </app-tags-input>
                        <button class="btn btn-primary btn-lg btn-rounded" (click)="saveToEmails()">
                            Save
                        </button>
                </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>

